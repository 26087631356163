<template>
  <div>
    <section class="c-section">
      <div class="c-section__inner">
        <h1 class="c-title5">COFFEE CREATION</h1>
        <PageListTable :pages="coffeeCreationPages" />
      </div>

      <div class="c-section__inner">
        <h1 class="c-title5">DRIP POD</h1>
        <PageListTable :pages="dripPodPages" />
      </div>

      <div class="c-section__inner">
        <h1 class="c-title5">開発用（本番では除外）</h1>
        <PageListTable :pages="devPages" />
      </div>
    </section>
  </div>
</template>

<script>
import { COFFEE_CREATION_PAGES, DRIP_POD_PAGES, DEV_PAGES } from '@/router.config';
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  components: {
    PageListTable: () => import('@/components/pageList/Table.vue')
  },

  setup: () => {
    const coffeeCreationPages = computed(() => {
      const routes = [];
      COFFEE_CREATION_PAGES.children.forEach((route) => {
        if (route.children) routes.push(...route.children);
        else routes.push(route);
      });
      return routes.sort((a, b) => a.path.localeCompare(b.path));
    });

    const dripPodPages = computed(() => {
      const routes = [];
      DRIP_POD_PAGES.children.forEach((route) => {
        if (route.children) routes.push(...route.children);
        else routes.push(route);
      });
      return routes.sort((a, b) => a.path.localeCompare(b.path));
    });

    const devPages = computed(() => {
      const routes = [];
      DEV_PAGES.forEach((route) => {
        if (route.children) routes.push(route.children[0]);
        else routes.push(route);
      });
      return routes.sort((a, b) => a.path.localeCompare(b.path));
    });

    return {
      coffeeCreationPages,
      dripPodPages,
      devPages
    };
  }
});
</script>
