var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "c-section" }, [
      _c(
        "div",
        { staticClass: "c-section__inner" },
        [
          _c("h1", { staticClass: "c-title5" }, [_vm._v("COFFEE CREATION")]),
          _c("PageListTable", { attrs: { pages: _vm.coffeeCreationPages } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "c-section__inner" },
        [
          _c("h1", { staticClass: "c-title5" }, [_vm._v("DRIP POD")]),
          _c("PageListTable", { attrs: { pages: _vm.dripPodPages } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "c-section__inner" },
        [
          _c("h1", { staticClass: "c-title5" }, [
            _vm._v("開発用（本番では除外）"),
          ]),
          _c("PageListTable", { attrs: { pages: _vm.devPages } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }